import SmartPhoto from "../../plugins/smartphoto.js";
import modal from '../../plugins/modal.js';

window.addEventListener("DOMContentLoaded", function() {
  new SmartPhoto(".js-smartPhoto", {
    resizeStyle: "fit"
  });
});

modal.init();

//$(".js-smartPhoto").click(function() {
  //   console.log(window.pageYOffset);
  //   console.log(document.documentElement.scrollTop);
  //   console.log(document.body.parentNode.scrollTop);
  //   console.log(document.body.scrollTop);
  // $(window).scrollTop($("html").data("scrTop"));
  //メニューを開いた位置へ移動
//});
