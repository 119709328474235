var pathname = function () {
  return location.pathname == "/index.php" ? "/" : location.pathname;
};

$(".l-header-nav-list-link").each(function () {
  var $href = $(this).attr("href");
  if (location.href.match($href) && $href != "/") {
    $(this).addClass("is-current");
  }
});
