
const breakPointBread = 768;


  /*--------------------------------
ロード時
---------------------------------*/
window.addEventListener('load',()=>{
  if( breakPointBread > window.innerWidth ){//pc
    //タイトル20文字以上は丸める
    let strimWidth = $(".js-strimWidth").text();
    const max_length2 = 5;
    let modStrBread = '';
    if(strimWidth.length > max_length2){
      modStrBread = strimWidth.substr(0, max_length2) + '...';
      $(".js-strimWidth").text(modStrBread);
    }
  }
},false);

/*--------------------------------
リサイズ時
---------------------------------*/
window.addEventListener('resize',()=>{
  if( breakPointBread > window.innerWidth ){//pc
    //タイトル20文字以上は丸める
    let strimWidth = $(".js-strimWidth").text();
    const max_length2 = 5;
    let modStrBread = '';
    if(strimWidth.length > max_length2){
      modStrBread = strimWidth.substr(0, max_length2) + '...';
      $(".js-strimWidth").text(modStrBread);
    }
  }
},false);
