var tabSwitchOpenClass = "-active";
var tabContentsOpenClass = "-active";


var tabParam = get_tab_param();

// タブのパラメータがついている時の対応
if($('[data-tab-content="' + tabParam + '"]').length > 0) {
    $('[data-tab-switch]').removeClass(tabSwitchOpenClass);
    $('[data-tab-switch="' + tabParam + '"]').addClass(tabSwitchOpenClass);
}

// 初期表示の設定
$('[data-tab-content]').removeClass(tabContentsOpenClass);
for (var i = 0; i < $('[data-tab-content]').length; i++) {
    var target = $('[data-tab-content]').eq(i).data('tabContent');
    if($('[data-tab-switch = ' + target + ']').hasClass(tabSwitchOpenClass)) {
        $('[data-tab-content]').eq(i).addClass(tabContentsOpenClass);
        break;
    }
}

// タブ切り替え
$('[data-tab-switch]').on('click', function(e) {
    var target = $(this).data('tabSwitch');
    tab_change(target);
});


// URLのパラメータ取得
function get_tab_param() {
    var params = [];
    var param = location.search.substring(1).split('&');
    for(var i = 0; i < param.length; i++) {
      params[i] = param[i].split('=');
    }
    for(var i = 0; i < params.length; i++) {
        if(params[i][0] === 'tab' &&  params[i][1] !== undefined) {
            return params[i][1];
        }
    }
    return false;
}

// タブ切り替えの処理
function tab_change(target) {
    $('[data-tab-switch]').removeClass(tabSwitchOpenClass);
    $('[data-tab-switch = ' + target + ']').addClass(tabSwitchOpenClass);
    $('[data-tab-content]').removeClass(tabContentsOpenClass);
    $('[data-tab-content = ' + target + ']').addClass(tabContentsOpenClass);
}
