// import '../../plugins/jquery.hasClasses.js';

//スクロールしたらbodyにis-scrolledを付与
// window.addEventListener("scroll", () => {
//   if(window.scrollY >= 1) {
//     document.querySelector('body').classList.add("is-scrolled");

//     // console.log($(window).scrollTop());
//   } else {
//     document.querySelector('body').classList.remove("is-scrolled");
//     // setTimeout(function() {
//     //   // if(!$('body').hasClass('is-open')) {
//     //   //   $('body').removeClass("is-scrolled");
//     //   // }
//     //   if(!$('body').hasClasses(['is-open', 'is-searchContent--showPage'], true)) {
//     //     $('body').removeClass("is-scrolled");
//     //   }

//     // }, 100);
//   }

// })


$(window).scroll(function() {
  if($(window).scrollTop() >= 1) {

    $('body').addClass("is-scrolled");
    // console.log($(window).scrollTop());
  } else {
    // setTimeout(function() {
    //   // if(!$('body').hasClass('is-open')) {
    //   //   $('body').removeClass("is-scrolled");
    //   // }
    //   if(!$('body').hasClasses(['is-open', 'is-searchContent--showPage'], true)) {
    //     $('body').removeClass("is-scrolled");
    //   }

    // }, 100);
    $('body').removeClass("is-scrolled");
  }
});


// $(function(){
// 	$(window).scroll(function (){
// 		$('.anim_elm').each(function(){
// 			var elemPos = $(this).offset().top;
// 			var scroll = $(window).scrollTop();
// 			var windowHeight = $(window).height();
// 			if (scroll > elemPos - windowHeight){
// 				$(this).addClass('is-act');
// 			}
// 		});
// 	});
// });


$(function(){
	$(window).scroll(function (){
		$('.animation-item').each(function(){
			var elemPos = $(this).offset().top;
			var scroll = $(window).scrollTop();
			var windowHeight = $(window).height();
			if (scroll > elemPos - windowHeight + 200){
				$(this).addClass('is-action');
			}
		});
	});
});
