import Cookies from "js-cookie";
import addEventListenerMultiType from './multiEventLister.js';

if($(".l-header-textSize-changer").length) {

class FontSizeChanger {
  constructor() {
    this.html = document.querySelector("html");
    this.targets = document.querySelectorAll(
      ".l-header-textSize-changer__item"
    );
    this.changer = document.querySelector(".l-header-textSize-changer");
  }

  init() {
    this.setFontSize();
    this.attachEvent();
  }

  setFontSize() {
    if(this.getFontSize() == 'large') {
      this.html.style.fontSize = '11px';
      this.changer.setAttribute("class", this.changer.getAttribute("class").replace("defult", "large"));
    }

    if(this.getFontSize() == 'defult') {
      this.html.style.fontSize = '10px';
      this.changer.setAttribute("class", this.changer.getAttribute("class").replace("large", "defult"));
    } 
  }

  getFontSize() {
    return Cookies.get('font-size');
  }

  attachEvent() {
    for (let target of this.targets) {
      addEventListenerMultiType(target,'click', (e) => {
        console.log(target.dataset.size);
        Cookies.set('font-size',target.dataset.size);
        this.setFontSize();
      });
    }
  }
}


  const fontSizeChanger = new FontSizeChanger();
  fontSizeChanger.init();

}