import isMobile from '../../plugins/is-mobile.js';
import addEventListenerMultiType from './multiEventLister.js';

class SpMenuContent {
  constructor(options) {
    this.body = document.querySelector('body');
    this.targets = document.querySelectorAll('.js-toggleMenu');
    this.autoClose = options.autoClose;
  }
  
  init() {
    this.attachEvent();
  }
  toggleSP(target) {
    if(target.classList.contains('is-toggleMenu--show')) {
      target.classList.remove('is-toggleMenu--show');
    } else {
      if(this.autoClose) {
        for (let target of this.targets) {
          target.classList.remove('is-toggleMenu--show');
        }
      }
      target.classList.add('is-toggleMenu--show');
    }
  }

  attachEvent() {
    for (let target of this.targets) {
      if(isMobile(768)){
        addEventListenerMultiType(target,'click', (e) => {
          this.toggleSP(target);
          if((e.target.classList.contains("toggle-main-link"))) {
            e.preventDefault()
          }
        },
        false);
      }
    }
  }
}

const options = {
  'autoClose': 1
}
const spMenuContent = new SpMenuContent(options);
spMenuContent.init();

